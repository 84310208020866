













import { Component, Vue } from 'vue-property-decorator';
import GeneralCrudList from '@/components/common/GeneralCrudList.vue';
import { generalDataService } from '@/services/GeneralDataService';

@Component({
  components: { GeneralCrudList },
})
export default class CompanyStructureGroup extends Vue {
  model: any = [
    { name: 'name', label: 'Nazwa', type: 'text' },
    { name: 'units', label: 'Sklepy', type: 'multiselect', options: [] },
  ];
  listOptions: any = [
    { field: 'name', label: 'Nazwa' },
    { field: 'units', label: 'Sklepy', options: {} },
  ];
  mounted() {
    generalDataService
      .list('generalList/structureUnit')
      .then(data => {
        this.model[1].options = data.map((item: any) => ({ name: item.name, id: item.id }));
        this.listOptions[1].options = {};
        data.map((item: any) => (this.listOptions[1].options[item.id] = item.name));
      })
      .catch(() => {});
  }
}
